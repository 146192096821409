import {
  ContentTypes,
  Employee,
  HttpFetchResponse,
  HttpMethod,
  Paginated,
  PaginationParameters,
  ProblemDetails,
  ProblemDetailsErrorsExtension,
  ProductDetailEmployeesEmployee
} from '~/app/shared';
import { ProjectEmployeesEmployee, ProjectEmployeesEmployeeDayByDay } from '~/app/shared/projects';

export interface EmployeesServiceGetByProductIdAndBillingCycleIdOptions {
  productId: number;
  billingCycleId: number;
  abortController: AbortController;
}

export interface GetByProjectIdAndBillingCycleIdBody {
  projectId: number;
  billingCycleId: number;
  abortController: AbortController;
}

export interface EmployeesServiceGetOptions {
  filter: string;
  paginationParameters: PaginationParameters;
  abortController: AbortController;
}

export interface EmployeesServiceGetCurrent {
  abortController: AbortController;
}

class EmployeesService {
  public async create(employee: Employee): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/employee';
    const body = {
      name: employee.name,
      unitId: employee.unit?.id,
      teamId: employee.team.id,
      managementTeamId: employee.managementTeam.id,
      code: employee.code || null,
      email: employee.email,
      hiringDate: employee.hiringDate || null,
      endDate: employee.endDate || null,
      area: employee.area,
      role: employee.role,
      employeeRoleId: employee.employeeRole?.id,
      isExternal: employee.isExternal
    };

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  private static getSortProperty(propertySort: string): string {
    const properties = {
      name: 'Name',
      email: 'Email',
      code: 'Code',
      hiringDate: 'HiringDate',
      endDate: 'EndDate',
      area: 'AreaName',
      employeeRole: 'EmployeeRole.Name',
      role: 'RoleName',
      unit: 'UnitName',
      team: 'TeamName',
      managementTeam: 'ManagementTeamName',
      isExternal: 'IsExternal'
    };

    return properties[propertySort] || properties.name;
  }

  public async get(options: EmployeesServiceGetOptions): Promise<HttpFetchResponse<Paginated<Employee>>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/employee/filtered';
    url.searchParams.set('pageNumber', `${options.paginationParameters.pageNumber}`);
    url.searchParams.set('pageSize', `${options.paginationParameters.pageSize}`);
    url.searchParams.set('propertySort', EmployeesService.getSortProperty(options.paginationParameters.propertySort));
    url.searchParams.set('isAscending', `${options.paginationParameters.isAscending}`);
    url.searchParams.set('filter', options.filter);

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getEngineers(skip = 0, take = 10, filter = ''): Promise<HttpFetchResponse<Paginated<Employee>>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/employee/engineers/${skip}/${take}`;

    if (filter) url.searchParams.set('filter', filter ?? '');

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getManagers(): Promise<HttpFetchResponse<Employee[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);

    url.pathname = '/employee/managers';

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getEmployeesExternal(): Promise<HttpFetchResponse<Employee[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);

    url.pathname = '/employee/externals';

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getEmployeesAccountControllers(): Promise<HttpFetchResponse<Employee[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);

    url.pathname = '/employee/accountControllers';

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getById(id: string | number): Promise<HttpFetchResponse<Employee>> {
    const url = new URL(process.env.REACT_APP_API_URL);

    url.pathname = `/employee/${id}`;

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getCurrent(options: EmployeesServiceGetCurrent): Promise<HttpFetchResponse<Employee>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/employee/current';

    const request: RequestInit = { signal: options.abortController.signal };

    const response = await fetch(url, request);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async delete(id: string | number): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/employee/${id}`;

    const response = await fetch(url, { method: HttpMethod.DELETE });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async toggleActive(id: string | number): Promise<void> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/employee/${id}`;

    await fetch(url, { method: HttpMethod.PUT });
  }

  public async update(employee: Employee): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/employee';
    const body = {
      name: employee.name,
      unitId: employee.unit?.id,
      teamId: employee.team.id,
      managementTeamId: employee.managementTeam.id,
      code: employee.code || null,
      email: employee.email,
      hiringDate: employee.hiringDate,
      isExternal: employee.isExternal,
      endDate: employee.endDate || null,
      area: employee.area,
      id: employee.id,
      role: employee.role,
      employeeRoleId: employee.employeeRole?.id
    };

    const response = await fetch(url, {
      method: HttpMethod.PUT,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getByProductIdAndBillingCycleId(
    options: EmployeesServiceGetByProductIdAndBillingCycleIdOptions
  ): Promise<HttpFetchResponse<ProductDetailEmployeesEmployee[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/employee/product/${options.productId}/billing-cycle/${options.billingCycleId}`;

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getByProjectIdAndBillingCycleIdDayByDay(
    options: GetByProjectIdAndBillingCycleIdBody
  ): Promise<HttpFetchResponse<ProjectEmployeesEmployeeDayByDay[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/employee/project/${options.projectId}/billing-cycle/${options.billingCycleId}/day-by-day`;

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getByProjectIdAndBillingCycleId(
    options: GetByProjectIdAndBillingCycleIdBody
  ): Promise<HttpFetchResponse<ProjectEmployeesEmployee[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/employee/project/${options.projectId}/billing-cycle/${options.billingCycleId}`;

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getOldRoles(): Promise<HttpFetchResponse<{ id: string; name: string }[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);

    url.pathname = '/employee/factorial-roles';

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getEmployeeRoles(): Promise<HttpFetchResponse<{ id: string; name: string }[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);

    url.pathname = '/employee/roles';

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody.roles, status: response.status };
    }

    return { data: null, status: response.status };
  }
}

export const employeesService = new EmployeesService();
