import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SingleValueProps, components } from 'react-select';
import { externalEffortService } from '~/app/external-efforts/external-efforts.service';
import { forecastService } from '~/app/forecast/shared';
import {
  AuthAction,
  AuthSubject,
  Employee,
  EmployeeArea,
  FormAsyncSelect,
  FormSelect,
  FormSelectOption,
  HttpStatus,
  ModalConfirmation,
  Team,
  Unit,
  __OldRole,
  enumToSortedByLabelSelect,
  extractErrorsFromProblemDetailsExtension,
  getDateFormatedPicker,
  isAbortError,
  longTeamName,
  teamsPromiseFilter,
  toastService,
  unitsPromiseFilter,
  unitsService,
  useCan
} from '~/app/shared';
import { EmployeeType } from '~/app/shared/employee/employee-type.enum';
import { teamsService } from '~/app/teams/shared';
import { RefreshStoreType, useRefreshStore } from '~/store';
import { employeesService } from '.';
import { EmployeesStoreType, useEmployeesStore } from '../employees.store';

export function EmployeesCreate({ id = null }) {
  const [t] = useTranslation();
  const unitsToSelectPromiseAbortController = useRef<AbortController>(new AbortController());
  const teamsToSelectPromiseAbortController = useRef<AbortController>(new AbortController());
  const managementTeamsToSelectPromiseAbortController = useRef<AbortController>(new AbortController());
  const [isSubmitting, setIsSubmitting] = useState(!!id);
  const [teams, setTeams] = useState<Team[]>();
  const [managementTeams, setManagementTeams] = useState<Team[]>();
  const [units, setUnits] = useState<Unit[]>();
  const [unitId, setUnitId] = useState<number>();
  const [isExternal, setEmployeeType] = useState<number>();
  const [, employeesDispatch] = useEmployeesStore();
  const [, refreshDispatch] = useRefreshStore();
  const { can } = useCan();
  const [displayExternalEmployeeConfirmationModal, setDisplayExternalEmployeeConfirmationModal] =
    useState<boolean>(false);
  const [displayConfirmationAfterDateModal, setDisplayConfirmationAfterDateModal] = useState<boolean>(false);
  const [displayConfirmationBeforeDateModal, setDisplayConfirmationBeforeDateModal] = useState<boolean>(false);
  const [displayConfirmationDateModal, setDisplayConfirmationDateModal] = useState<boolean>(false);

  const [employeeId, setEmployeeId] = useState(0);
  const [endDate, setEndDate] = useState(new Date());
  const [hiringDate, setHiringDate] = useState(new Date());
  const [newHiringDate, setNewHiringDate] = useState(new Date());
  const [employee, setEmployee] = useState<Employee>();
  const [countAfterDate, setCountAfterDate] = useState<number>(0);
  const [countBeforeDate, setCountBeforeDate] = useState<number>(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch
  } = useForm();

  const areaList = useMemo(() => enumToSortedByLabelSelect(EmployeeArea), []);
  const employeeTypeList = useMemo(() => enumToSortedByLabelSelect(EmployeeType), []);
  const oldRolesSelectPromise = useCallback(async (): Promise<FormSelectOption<string>[]> => {
    const response = await employeesService.getOldRoles();

    return response.data.map((x) => ({ value: x.id, label: x.name }));
  }, []);

  const employeeRolesSelectPromise = useCallback(async (): Promise<FormSelectOption<string>[]> => {
    const response = await employeesService.getEmployeeRoles();

    return response.data.map((x) => ({ value: x.id, label: x.name }));
  }, []);

  const isRoleAssignationDisabled = useMemo(() => !can(AuthAction.Manage, AuthSubject.EmployeeRole), [can]);
  const isRoleAssignationHidden = useMemo(() => isRoleAssignationDisabled && !id, [isRoleAssignationDisabled, id]);

  const watchedUnit = watch('unit');
  const watchedEmployeeType = watch('isExternal');
  const watchedId = watch('id');

  async function showDeleteAfterDateModal(count: number | string): Promise<void> {
    setDisplayConfirmationAfterDateModal(true);
  }

  async function showDeleteDateModal(count: number | string): Promise<void> {
    setDisplayConfirmationDateModal(true);
  }

  async function showDeleteBeforeDateModal(count: number | string): Promise<void> {
    setDisplayConfirmationBeforeDateModal(true);
  }

  async function showDeleteExternalEmployeeModal(countExternalEmployeeEffort: number | string): Promise<void> {
    setDisplayExternalEmployeeConfirmationModal(true);
  }

  function hideConfirmationAfterDateModal(): void {
    setDisplayConfirmationAfterDateModal(false);
    return;
  }

  function hideConfirmationBeforeDateModal(): void {
    setDisplayConfirmationBeforeDateModal(false);
    return;
  }

  function hideConfirmationDateModal(): void {
    setDisplayConfirmationDateModal(false);
    return;
  }

  function hideExternalEmployeeConfirmationModal(): void {
    setDisplayExternalEmployeeConfirmationModal(false);
    return;
  }

  const deleteExternalEmployeeEffort = useCallback(async (): Promise<void> => {
    try {
      const response = await externalEffortService.deleteAfterDate(employeeId, endDate);
      console.log(endDate);
      if (response.status === HttpStatus.StatusOK) {
        toastService.success(t('external-efforts.delete.success'));
        updateEmployee(employeeId, employee);
      }

      if (response.status === HttpStatus.StatusBadRequest) {
        const errors = extractErrorsFromProblemDetailsExtension(response.data, { format: 'list' });
        toastService.errorMessage(errors as string);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        toastService.error(error);
      }
    } finally {
      setDisplayExternalEmployeeConfirmationModal(false);
      onClickCancel();
    }
  }, [employee, employeeId, t, updateEmployee]);

  const deleteForecastAfterDate = useCallback(async (): Promise<void> => {
    try {
      const response = await forecastService.delete(employeeId, endDate);

      if (response.status === HttpStatus.StatusOK) {
        toastService.success(t('forecast.delete.success'));
        updateEmployee(employeeId, employee);
      }

      if (response.status === HttpStatus.StatusBadRequest) {
        const errors = extractErrorsFromProblemDetailsExtension(response.data, { format: 'list' });
        toastService.errorMessage(errors as string);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        toastService.error(error);
      }
    } finally {
      setDisplayConfirmationAfterDateModal(false);
      onClickCancel();
    }
  }, [employeeId, endDate, employee, refreshDispatch, t]);

  const deleteForecastBeforeDate = useCallback(async (): Promise<void> => {
    try {
      const response = await forecastService.deleteBefore(employeeId, newHiringDate);

      if (response.status === HttpStatus.StatusOK) {
        toastService.success(t('forecast.delete.success'));
        updateEmployee(employeeId, employee);
      }

      if (response.status === HttpStatus.StatusBadRequest) {
        const errors = extractErrorsFromProblemDetailsExtension(response.data, { format: 'list' });
        toastService.errorMessage(errors as string);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        toastService.error(error);
      }
    } finally {
      setDisplayConfirmationBeforeDateModal(false);
      onClickCancel();
    }
  }, [employeeId, newHiringDate, t, updateEmployee, employee]);

  const deleteForecastDate = useCallback(async (): Promise<void> => {
    try {
      const responseBefore = await forecastService.deleteBefore(employeeId, newHiringDate);
      const responseAfter = await forecastService.delete(employeeId, endDate);

      if (responseBefore.status === HttpStatus.StatusOK && responseAfter.status === HttpStatus.StatusOK) {
        toastService.success(t('forecast.delete.success'));
        updateEmployee(employeeId, employee);
      }

      if (
        responseBefore.status === HttpStatus.StatusBadRequest ||
        responseAfter.status === HttpStatus.StatusBadRequest
      ) {
        const errorsBefore = extractErrorsFromProblemDetailsExtension(responseBefore.data, { format: 'list' });
        const errorsAfter = extractErrorsFromProblemDetailsExtension(responseAfter.data, { format: 'list' });

        const allErrors = errorsBefore + '\n\n' + errorsAfter;
        toastService.errorMessage(allErrors as string);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        toastService.error(error);
      }
    } finally {
      setDisplayConfirmationDateModal(false);
      onClickCancel();
    }
  }, [employeeId, newHiringDate, endDate, t, updateEmployee, employee]);

  useEffect(() => {
    function asyncRefreshUnit() {
      setUnitId(undefined);
      setTimeout(() => setUnitId(Number(watchedUnit?.value)), 10);
    }

    asyncRefreshUnit();
  }, [watchedUnit?.value]);

  useEffect(() => {
    function asyncRefreshEmployeeType() {
      setEmployeeType(undefined);
      setTimeout(() => setEmployeeType(Number(watchedEmployeeType?.value)), 10);
    }
    asyncRefreshEmployeeType();
  }, [watchedEmployeeType?.value]);

  useEffect(() => {
    function onLeave() {
      unitsToSelectPromiseAbortController.current.abort();
      teamsToSelectPromiseAbortController.current.abort();

      refreshDispatch({ type: RefreshStoreType.SET_REFRESH });
      employeesDispatch({ type: EmployeesStoreType.SET_SELECTED, payload: null });

      reset({});
    }

    return onLeave;
  }, [employeesDispatch, refreshDispatch, reset]);

  useEffect(() => {
    async function fetchDataIfIdExists() {
      if (!id) {
        return;
      }

      setIsSubmitting(true);

      const { data: employee } = await employeesService.getById(id);
      const area = areaList.find((el) => el.value === `${employee.area}`);
      const role = enumToSortedByLabelSelect(__OldRole).find((el) => el.value === `${employee.role}`);
      const isExternalValue = employee.isExternal ? 1 : 0;
      const external = enumToSortedByLabelSelect(EmployeeType).find((el) => el.value === `${isExternalValue}`);
      const employeeRole = employee.employeeRole
        ? { value: employee.employeeRole?.id, label: employee.employeeRole?.name }
        : null;
      const team = employee.team ? { value: employee.team?.id, label: longTeamName(employee.team as Team) } : null;
      const managementTeam = employee.managementTeam
        ? { value: employee.managementTeam.id, label: longTeamName(employee.managementTeam as Team) }
        : null;
      const unit = employee.unit ? { value: employee.unit?.id, label: employee.unit?.name } : null;
      setHiringDate(employee.hiringDate);
      reset({
        name: employee.name,
        email: employee.email,
        code: employee.code,
        hiringDate: getDateFormatedPicker(employee.hiringDate),
        isExternal: external,
        endDate: employee.endDate ? getDateFormatedPicker(employee.endDate) : '',
        area,
        team,
        managementTeam,
        id: employee.id,
        role,
        employeeRole,
        unit
      });

      setIsSubmitting(false);
    }

    fetchDataIfIdExists().catch((error) => {
      if (!isAbortError(error)) {
        console.error(error);
      }
    });
  }, [areaList, id, reset]);

  function resetForm(force = true) {
    employeesDispatch({ type: EmployeesStoreType.SET_SELECTED, payload: null });
    reset({});

    if (force) {
      refreshDispatch({ type: RefreshStoreType.SET_REFRESH });
    }
  }

  function isRegularEmployee(watchedEmployeeType) {
    if (watchedEmployeeType === '0' || watchedEmployeeType === undefined) return true;
    return false;
  }

  function isEditing() {
    if (id !== null) return true;
    return false;
  }

  async function updateEmployee(employeeId: number | null, employee: Employee) {
    const response = await employeesService.update({ id: employeeId, ...employee });

    if (response.status === HttpStatus.StatusOK) {
      toastService.success(t('employees.create.success-update'));
      resetForm(true);
    } else {
      const errors = extractErrorsFromProblemDetailsExtension(response.data, { format: 'list' });
      const errorsString = errors as string;
      toastService.errorMessage(errorsString);
    }
  }

  const compareStringDates = (date1: string, date2: string): boolean => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  };

  const onSubmit = async (data) => {
    if (errors?.length) return;
    if (data.isExternal === undefined) data.isExternal = '0';
    setIsSubmitting(true);
    const employee: Employee = {
      name: data.name,
      team: data.isExternal.value === '1' ? { id: null } : { id: data.team?.value },
      managementTeam: data.isExternal.value === '1' ? { id: null } : { id: data.managementTeam?.value },
      unit: data.isExternal.value === '1' ? { id: null } : { id: data.unit?.value },
      email: data.email,
      hiringDate: data.hiringDate,
      endDate: data.endDate,
      area: data.area?.value,
      code: data.isExternal.value === '1' ? null : data.code,
      role: data.role?.value,
      employeeRole: { id: data.employeeRole?.value },
      isExternal: data.isExternal.value === '1' ? true : false
    };

    try {
      if (data.id) {
        const endDate = employee.endDate;

        const employeeId = data.id;
        setEmployeeId(employeeId);
        setEmployee(employee);
        if (!isRegularEmployee(watchedEmployeeType?.value)) {
          if (endDate) {
            const countExternalEmployeeEffort = await externalEffortService.countExternalEffortAfterDate(
              data.endDate,
              data.id
            );
            if (countExternalEmployeeEffort.data == null) {
              toastService.errorMessage(t('employees.edit.error-month-billed'));
              return;
            }
            if (countExternalEmployeeEffort.data !== 0) {
              setEndDate(endDate);
              setCountAfterDate(countExternalEmployeeEffort.data);
              showDeleteExternalEmployeeModal(countExternalEmployeeEffort.data);
            }
          } else {
            updateEmployee(employeeId, employee);
            resetForm();
          }
        } else {
          if (!compareStringDates(hiringDate.toString(), data.hiringDate) && data.endDate === '') {
            const response = await forecastService.countForecastsBeforeDate(data.hiringDate, data.id);
            if (response.status === HttpStatus.StatusBadRequest) {
              toastService.errorMessage(t('employees.edit.error-month-billed-before'));
              return;
            }
            if (response.data !== 0) {
              setNewHiringDate(data.hiringDate);
              setCountBeforeDate(response.data);
              showDeleteBeforeDateModal(countAfterDate);
            } else {
              updateEmployee(employeeId, employee);
              resetForm();
            }
          } else if (data.endDate !== '' && compareStringDates(hiringDate.toString(), data.hiringDate)) {
            const response = await forecastService.countForecastsAfterDate(data.endDate, data.id);
            if (response.status === HttpStatus.StatusBadRequest) {
              toastService.errorMessage(t('employees.edit.error-month-billed'));
              return;
            }
            setEndDate(endDate);
            if (response.data !== 0) {
              setCountAfterDate(response.data);
              showDeleteAfterDateModal(countAfterDate);
            } else {
              updateEmployee(employeeId, employee);
              resetForm();
            }
          } else if (!compareStringDates(hiringDate.toString(), data.hiringDate) && data.endDate !== '') {
            const responseBefore = await forecastService.countForecastsBeforeDate(data.hiringDate, data.id);
            if (responseBefore.status === HttpStatus.StatusBadRequest) {
              toastService.errorMessage(t('employees.edit.error-month-billed-before'));
              return;
            }
            const responseAfter = await forecastService.countForecastsAfterDate(data.endDate, data.id);
            if (responseAfter.status === HttpStatus.StatusBadRequest) {
              toastService.errorMessage(t('employees.edit.error-month-billed'));
              return;
            }
            if (responseBefore.data !== 0 || responseAfter.data !== 0) {
              setNewHiringDate(data.hiringDate);
              setCountBeforeDate(responseBefore.data);
              setEndDate(endDate);
              setCountAfterDate(responseAfter.data);
              showDeleteDateModal(countAfterDate);
            } else {
              updateEmployee(employeeId, employee);
              resetForm();
            }
          } else {
            updateEmployee(employeeId, employee);
            resetForm();
          }
        }
      } else {
        const response = await employeesService.create(employee);
        if (response.status === HttpStatus.StatusOK) {
          toastService.success(t('employees.create.success-create'));

          resetForm();
        } else {
          const errors = extractErrorsFromProblemDetailsExtension(response.data, { format: 'list' });

          toastService.errorMessage(errors as string);
        }
      }
    } catch (error) {
      if (!isAbortError(error)) {
        toastService.error(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onClickCancel = () => {
    setIsSubmitting(true);
    resetForm(false);
    setTimeout(() => setIsSubmitting(false), 10);
  };

  const teamsToSelectPromise = async (inputValue: string) => {
    teamsToSelectPromiseAbortController.current.abort();
    teamsToSelectPromiseAbortController.current = new AbortController();

    return teamsPromiseFilter(
      teams,
      (abortController) => unitsService.getByIdTakeTeams(unitId, true, abortController),
      setTeams,
      inputValue,
      teamsToSelectPromiseAbortController.current,
      true
    );
  };

  const managementTeamsToSelectPromise = async (inputValue: string) => {
    managementTeamsToSelectPromiseAbortController.current.abort();
    managementTeamsToSelectPromiseAbortController.current = new AbortController();

    const { data } = await teamsService.get({
      paginationParameters: {
        pageNumber: 0,
        pageSize: 50,
        propertySort: 'Number',
        isAscending: true
      },
      filter: inputValue,
      isManagement: true,
      abortController: managementTeamsToSelectPromiseAbortController.current
    });

    setManagementTeams(data.items);

    return data.items.map((team) => ({ value: team.id, label: longTeamName(team) }));
  };

  function unitsToSelectPromise(inputValue: string): Promise<FormSelectOption<unknown>[]> {
    if (unitsToSelectPromiseAbortController.current) {
      unitsToSelectPromiseAbortController.current.abort();
    }

    unitsToSelectPromiseAbortController.current = new AbortController();

    return unitsPromiseFilter(
      units,
      unitsService.getAll,
      setUnits,
      inputValue,
      true,
      unitsToSelectPromiseAbortController.current
    );
  }

  // TODO: This is NEVER ok, components should be declared outside other components,
  // but it's like this because this page is being done from 0.
  const SingleValueTeam = ({ ...props }: SingleValueProps) => {
    const option: FormSelectOption = props.data;

    const team = teams?.find((el) => el?.id === option.value);
    if (!team) {
      return null;
    }

    return <components.SingleValue {...props}>{longTeamName(team)}</components.SingleValue>;
  };

  const SingleValueManagementTeam = ({ ...props }: SingleValueProps) => {
    const option: FormSelectOption = props.data;

    const team = managementTeams?.find((el) => el?.id === option.value);
    if (!team) {
      return null;
    }

    return <components.SingleValue {...props}>{longTeamName(team)}</components.SingleValue>;
  };

  const SingleValueUnit = ({ ...props }: SingleValueProps) => {
    const option: FormSelectOption = props.data;

    const unit = units?.find((el) => el?.id === option.value);

    if (!unit) {
      return null;
    }

    return <components.SingleValue {...props}>{unit.name}</components.SingleValue>;
  };

  return (
    <>
      <Card bg="light" className="mb-3">
        <Card.Header>{t(id ? 'employees.edit.title' : 'employees.create.title', [id])}</Card.Header>
        <Card.Body>
          {isSubmitting ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label className="form-label-required">{t('employees.create.name')}</Form.Label>
                <Form.Control
                  {...register('name', {
                    required: { value: true, message: t('form.errors.required') },
                    maxLength: { value: 100, message: t('form.errors.maxLength') }
                  })}
                  isInvalid={errors.name}
                  placeholder={t('employees.create.name-placeholder')}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label className="form-label-required">{t('employees.create.email')}</Form.Label>
                <Form.Control
                  {...register('email', {
                    required: { value: true, message: t('form.errors.required') },
                    maxLength: { value: 254, message: t('form.errors.maxLength') }
                  })}
                  isInvalid={errors.email}
                  placeholder={t('employees.create.email-placeholder')}
                />
                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
              </Form.Group>

              {!isRoleAssignationHidden && (
                <Form.Group className="mb-3" controlId="role">
                  <Form.Label className="form-label-required">{t('employees.create.role')}</Form.Label>
                  <FormAsyncSelect
                    name="role"
                    control={control}
                    placeholder={t('employees.create.role-placeholder')}
                    promiseOptions={oldRolesSelectPromise}
                    isMultiple={false}
                    disabled={isRoleAssignationDisabled}
                  />

                  <Form.Control.Feedback type="invalid">{errors.role?.message}</Form.Control.Feedback>
                </Form.Group>
              )}

              {!isRoleAssignationHidden && (
                <Form.Group className="mb-3" controlId="employeeRole">
                  <Form.Label className="form-label-required">{t('employees.create.employee-role')}</Form.Label>
                  <FormAsyncSelect
                    name="employeeRole"
                    control={control}
                    placeholder={t('employees.create.employee-role-placeholder')}
                    promiseOptions={employeeRolesSelectPromise}
                    isMultiple={false}
                    disabled={isRoleAssignationDisabled}
                  />
                  <Form.Control.Feedback type="invalid">{errors.employeeRole?.message}</Form.Control.Feedback>
                </Form.Group>
              )}

              {isRegularEmployee(watchedEmployeeType?.value) && (
                <Form.Group className="mb-3" controlId="code">
                  <Form.Label className="form-label-required">{t('employees.create.code')}</Form.Label>
                  <Form.Control
                    {...register('code', {
                      required: { value: true, message: t('form.errors.required') },
                      maxLength: { value: 50, message: t('form.errors.maxLength') }
                    })}
                    isInvalid={errors.code}
                    placeholder={t('employees.create.code-placeholder')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.code?.message}</Form.Control.Feedback>
                </Form.Group>
              )}

              <Form.Group className="mb-3" controlId="hiringDate">
                <Form.Label className="form-label-required">{t('employees.create.hiringDate')}</Form.Label>
                <Form.Control
                  type="date"
                  {...register('hiringDate', {
                    required: { value: true, message: t('form.errors.required') }
                  })}
                  isInvalid={errors.hiringDate}
                />
                <Form.Control.Feedback type="invalid">{errors.hiringDate?.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="endDate">
                <Form.Label>{t('employees.create.endDate')}</Form.Label>
                <Form.Control {...register('endDate')} type="date" isInvalid={errors.endDate} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="area">
                <Form.Label className="form-label-required">{t('employees.create.area')}</Form.Label>
                <FormSelect
                  name="area"
                  placeholder={t('employees.create.area-placeholder')}
                  options={areaList}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.errors.required')
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">{errors.area?.message}</Form.Control.Feedback>
              </Form.Group>

              {/* <Can I={AuthAction.Manage} a={AuthSubject.EmployeeExternal}> */}
              <Form.Group className="mb-3" controlId="isExternal">
                <Form.Label className="form-label-required">{t('employees.create.type')}</Form.Label>
                <FormSelect
                  name="isExternal"
                  control={control}
                  placeholder={t('employees.create.type-placeholder')}
                  options={employeeTypeList}
                  defaultValue={employeeTypeList.find((x) => x.label === 'Regular')}
                  isMultiple={false}
                  isDisabled={isEditing()}
                />
                <Form.Control.Feedback type="invalid">{errors.employeeIsExternal?.message}</Form.Control.Feedback>
              </Form.Group>
              {/* </Can> */}

              {isRegularEmployee(watchedEmployeeType?.value) && (
                <Form.Group className="mb-3" controlId="unit">
                  <Form.Label>{t('employees.create.unit')}</Form.Label>
                  <FormAsyncSelect
                    name="unit"
                    placeholder={t('employees.create.unit-placeholder')}
                    promiseOptions={unitsToSelectPromise}
                    control={control}
                    components={{ SingleValue: SingleValueUnit }}
                    isClearable={true}
                  />
                  <Form.Control.Feedback type="invalid">{errors.unit?.message}</Form.Control.Feedback>
                </Form.Group>
              )}

              {!isNaN(unitId) && isRegularEmployee(watchedEmployeeType?.value) && (
                <>
                  <Form.Group className="mb-3" controlId="team">
                    <Form.Label>{t('employees.create.team')}</Form.Label>
                    <FormAsyncSelect
                      name="team"
                      placeholder={t('employees.create.team-placeholder')}
                      promiseOptions={teamsToSelectPromise}
                      control={control}
                      components={{ SingleValue: SingleValueTeam }}
                      isClearable={true}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="managementTeam">
                    <Form.Label>{t('employees.create.managementTeam')}</Form.Label>
                    <FormAsyncSelect
                      name="managementTeam"
                      placeholder={t('employees.create.team-placeholder')}
                      promiseOptions={managementTeamsToSelectPromise}
                      control={control}
                      components={{ SingleValue: SingleValueManagementTeam }}
                      isClearable={true}
                    />
                  </Form.Group>
                </>
              )}

              <div className="d-flex justify-content-end">
                <Button variant="secondary" type="reset" className="mx-2" onClick={onClickCancel}>
                  {t('common.cancel')}
                </Button>

                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <Spinner animation="border" size="sm" className="ml-2" /> : t('common.save')}
                </Button>
              </div>
            </Form>
          )}
        </Card.Body>
      </Card>
      <ModalConfirmation
        showModal={displayExternalEmployeeConfirmationModal}
        bodyModal={t('forecast.actions.external-employee') + countAfterDate + '. ' + t('forecast.actions.delete')}
        confirmModal={deleteExternalEmployeeEffort}
        hideModal={hideExternalEmployeeConfirmationModal}
      />
      <ModalConfirmation
        showModal={displayConfirmationAfterDateModal}
        bodyModal={t('forecast.actions.after-date') + countAfterDate + '. ' + t('forecast.actions.delete')}
        confirmModal={deleteForecastAfterDate}
        hideModal={hideConfirmationAfterDateModal}
      />
      <ModalConfirmation
        showModal={displayConfirmationBeforeDateModal}
        bodyModal={t('forecast.actions.before-date') + countBeforeDate + '. ' + t('forecast.actions.delete')}
        confirmModal={deleteForecastBeforeDate}
        hideModal={hideConfirmationBeforeDateModal}
      />
      <ModalConfirmation
        showModal={displayConfirmationDateModal}
        bodyModal={
          t('forecast.actions.before-date') +
          countBeforeDate +
          '. ' +
          t('forecast.actions.delete') +
          '\n' +
          t('forecast.actions.after-date') +
          countAfterDate +
          '. ' +
          t('forecast.actions.delete')
        }
        confirmModal={deleteForecastDate}
        hideModal={hideConfirmationDateModal}
      />
    </>
  );
}
