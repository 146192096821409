import { Dispatch, SetStateAction } from 'react';
import { HttpFetchResponse, isAbortError, transformIntegerToStringFormatSync } from '~/app/shared';
import { groupBy } from '~/utils/arrayUtils';
import { FormSelectOption } from '../form';
import { Team } from './team.model';

export function longTeamName(team: Team): string {
  if (team.isManagement) {
    return team.manager.name;
  }

  if (!team?.unit) {
    return getTeamName(team.number);
  }

  if (!team.unit.businessLine) {
    return `${team.unit.name} - ${getTeamName(team.number)}`;
  }

  return `${team.unit.businessLine?.name} - ${team.unit.name} - ${getTeamName(team.number)}`;
}

export function shortTeamName(team: Team): string {
  return team.isManagement ? team.manager.name : getTeamName(team.number);
}

export function getTeamName(input: number): string {
  return transformIntegerToStringFormatSync(input, { preffix: 'Team ' });
}

type TeamWithLabel = Team & {
  label: string;
};

export async function teamsPromiseFilter(
  teams: Team[],
  getAll: (abortController: AbortController) => Promise<HttpFetchResponse<Team[]>>,
  setTeams: Dispatch<SetStateAction<Team[]>>,
  inputValue: string,
  abortController: AbortController,
  removeManagement = false
) {
  let teamsFiltered: TeamWithLabel[] = teamsToTeamsWithLabel(teams ?? []).filter((team) =>
    team.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  if (!inputValue) {
    try {
      const { data } = await getAll(abortController);
      setTeams(data);

      const teamsWithLabel = teamsToTeamsWithLabel(data);

      if (removeManagement) {
        teamsFiltered = teamsWithLabel.filter((team) => team.isManagement === false);
      } else {
        teamsFiltered = teamsWithLabel;
      }
    } catch (error) {
      if (!isAbortError(error)) {
        console.error(error);
      }
    }
  }

  if (!teamsFiltered || !teamsFiltered.length) {
    return [];
  }

  const groupedAndSortedTeams = groupAndSortTeams(teamsFiltered);
  return groupedAndSortedTeams.map((team) => ({ value: team.id, label: team.label }));
}

function teamsToTeamsWithLabel(teams: Team[]): TeamWithLabel[] {
  return teams.map((team) => ({ label: longTeamName(team), ...team }));
}

function groupAndSortTeams(teams: TeamWithLabel[]): TeamWithLabel[] {
  const groupedTeams = groupBy(teams, 'isManagement');

  const deliveryTeams = groupedTeams['false'] ?? [];
  const managementTeams = groupedTeams['true'] ?? [];

  return deliveryTeams
    .sort((a, b) => a.label.localeCompare(b.label))
    .concat(managementTeams.sort((a, b) => a.label.localeCompare(b.label)));
}

export async function teamsByUnitPromiseFilter(
  teams: Team[],
  getByUnitId: (unitId: number) => Promise<Team[]>,
  setTeams: Dispatch<SetStateAction<Team[]>>,
  inputValue: string,
  unitId?: number
) {
  const text = inputValue.toLowerCase();
  let teamsFiltered = teams?.filter((x) => longTeamName(x).toLowerCase().includes(text));

  if (!inputValue || !unitId) {
    teamsFiltered = await getByUnitId(unitId);
    setTeams(teamsFiltered);
  }

  return teamsFiltered
    ?.slice(0, 10)
    .map((x) => ({ value: x.id, label: longTeamName(x) }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export async function teamsByUnitsPromiseFilter(
  teams: Team[],
  getByUnitId: (unitId: number, abortController) => Promise<HttpFetchResponse<Team[]>>,
  setTeams: Dispatch<SetStateAction<Team[]>>,
  inputValue: string,
  unitsIds: number[],
  abortController: AbortController
): Promise<FormSelectOption[]> {
  const text = inputValue?.toLowerCase();
  let teamsFiltered = teams?.filter((x) => longTeamName(x).toLowerCase().includes(text));

  if (!inputValue || unitsIds.length < 1) {
    try {
      const promises = unitsIds.map((x) => getByUnitId(x, abortController));
      const results = await Promise.all(promises);
      teamsFiltered = results.map((result) => result.data).flat();

      setTeams(teamsFiltered);
    } catch (error) {
      if (!isAbortError(error)) {
        console.error(error);
      }
    }
  }

  return teamsFiltered
    .map((x) => ({ value: x.id, label: longTeamName(x) }))
    .sort((a, b) => a.label.localeCompare(b.label));
}
