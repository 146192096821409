import { addDays, differenceInBusinessDays, format, isToday, isWeekend } from 'date-fns';
import { useCallback, useState } from 'react';
import {
  AuthAction,
  AuthSubject,
  DynamicStyleClass,
  ProductStatus,
  getProductCellBulletStyles,
  useCan
} from '~/app/shared';
import { ForecastCell, ForecastProductCellSelector, ProductSelectorValueProps, useForecastStore } from '.';
import { RenderBodyCellDrag } from './RenderBodyCellDrag';
import { useForecastCell } from './forecast-cell.hook';
import { useForecastCellStore } from './forecast-cell.store';
import { isDateSprintMark } from './forecast.helper';

type RenderBodyCellProps = {
  timeline: ForecastCell[];
  columnIndex: number;
  rowHeight: number;
  columnWidth: number;
  addProductAfterDragCell: (
    cell: ForecastCell,
    rawX: number,
    extraX: number,
    rawY: number,
    extraY: number,
    isExpanding?: any
  ) => Promise<void>;
  sprintMarks: string[];
  addNewAssignedCell: (cell: ForecastCell) => Promise<void>;
  addNewAssignedBlock: (cell: ForecastCell, oldCell: ForecastCell) => Promise<void>;
  scrolledFocusedDate: any;
  style: any;
  isLoading: boolean;
  isLocked: boolean;
};

export function RenderBodyCell({
  timeline,
  columnIndex,
  style,
  rowHeight,
  columnWidth,
  addProductAfterDragCell,
  sprintMarks,
  addNewAssignedCell,
  addNewAssignedBlock,
  scrolledFocusedDate,
  isLoading,
  isLocked
}: RenderBodyCellProps) {
  const { can } = useCan();
  const [forecastState] = useForecastStore();
  const [forecastCellState] = useForecastCellStore();
  const [selectedColumnIndex, setSelectedColumnIndex] = useState<number>();
  const [clickedColumn, setClickedColumn] = useState<number>();
  const { isPreviousTheSame, maxNextTheSame, commentsList } = useForecastCell(timeline, columnIndex);

  const cell: ForecastCell = timeline[columnIndex];
  const endDate = new Date(cell?.employee?.endDate);
  const hiringDate = new Date(cell?.employee?.hiringDate);
  const isFinished = `${cell?.product?.status}` === ProductStatus.Finished;
  const isSelected = !!selectedColumnIndex;

  const { product, color, ...productBulletStyles } = getProductCellBulletStyles(cell);

  const canEditForecastFromPast = can(AuthAction.Manage, AuthSubject.ForecastFromPast);
  const columnSpacing = clickedColumn ? clickedColumn * columnWidth : columnWidth;
  const noBillingOrCanEditFromPast = !cell.haveBilling || canEditForecastFromPast;

  function onClickCell(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();
    event.preventDefault();

    if (isLocked || clickedColumn || forecastCellState.currentlyDragging || !noBillingOrCanEditFromPast) {
      return;
    }

    const clickedCellColumn = Math.ceil(
      (event?.clientX - event?.currentTarget?.getBoundingClientRect()?.x) / columnWidth
    );

    let newSelectedColumnIndex = columnIndex;
    if (clickedCellColumn > 1) {
      newSelectedColumnIndex = columnIndex + clickedCellColumn - 1;
    }

    setSelectedColumnIndex(newSelectedColumnIndex);
    setClickedColumn(clickedCellColumn);
  }

  async function onClickEmptyCell() {
    if (isLocked || forecastCellState.currentlyDragging || !noBillingOrCanEditFromPast) {
      return;
    }

    setSelectedColumnIndex(columnIndex);
  }

  const onChangeProductSelectorValue = useCallback(
    (props: ProductSelectorValueProps, isAll = false) => {
      const { product, specialEffort, comment, billableType } = props;
      const selectedCell = selectedColumnIndex ? timeline[selectedColumnIndex] : undefined;

      const selectedCellCopy: ForecastCell = JSON.parse(JSON.stringify(selectedCell));
      selectedCellCopy.product = product;
      selectedCellCopy.billableType = billableType;
      selectedCellCopy.date = new Date(selectedCellCopy.date);
      selectedCellCopy.specialEffort = specialEffort;
      selectedCellCopy.comment = comment;

      if (isAll) {
        addNewAssignedBlock(selectedCellCopy, selectedCell);
        return;
      }

      addNewAssignedCell(selectedCellCopy);
    },
    [addNewAssignedBlock, addNewAssignedCell, selectedColumnIndex, timeline]
  );

  const onCloseProductSelector = useCallback(() => {
    setTimeout(() => {
      setSelectedColumnIndex(null);
      setClickedColumn(null);
    }, 10);
  }, []);

  if (columnIndex < 0 || timeline === undefined || timeline?.length <= 1) {
    return <div></div>;
  }

  const customStyles = {
    width: style.width,
    height: style.height
  };

  const basicClasses: DynamicStyleClass = new DynamicStyleClass().fromRecord({
    forecast__cell: true,
    'forecast__cell--is-locked': isLocked,
    'forecast__cell--today': isToday(cell?.date),
    'forecast__cell--even': columnIndex % 2 === 0,
    'forecast__cell--first': isDateSprintMark(cell?.date, sprintMarks)
  });

  if (forecastState.visibleEndDay < hiringDate) {
    return null;
  }

  // If is weekend or endDate or hiring arrived we show the weekend cell
  if (
    isWeekend(cell?.date) ||
    differenceInBusinessDays(endDate, cell?.date) < 0 ||
    differenceInBusinessDays(cell?.date, hiringDate) < 0
  ) {
    return <div className={basicClasses.clone().add('forecast__cell--not-allowed').toString()} style={style} />;
  }

  const wasYesterdayWeekend = isWeekend(addDays(cell?.date, -1));
  const isWeekendTomorrow = isWeekend(addDays(cell?.date, 1));
  const isOpen = isSelected && !isLocked && !isFinished && noBillingOrCanEditFromPast;

  // If there is no product we show the empty cell
  if ((!cell?.product && !cell.specialEffort) || forecastState.visibleEndDay < hiringDate) {
    return (
      <div
        className={basicClasses
          .clone()
          .fromRecord({
            'forecast__cell--weekend': wasYesterdayWeekend,
            'forecast__cell--preweekend': isWeekendTomorrow,
            'is-open': isOpen
          })
          .toString()}
        onClick={() => onClickEmptyCell()}
        style={style}
      >
        {isSelected && !isLocked && noBillingOrCanEditFromPast && (
          <ForecastProductCellSelector
            cell={cell}
            isNew={true}
            hideBlockActions={true}
            onClose={onCloseProductSelector}
            spacing={columnSpacing}
            onChange={onChangeProductSelectorValue}
            scrolledFocusedDate={scrolledFocusedDate}
            columnWidth={columnWidth}
          />
        )}
      </div>
    );
  }

  const showProductCellSelector = isSelected && !isLocked && !isFinished && !isLoading && noBillingOrCanEditFromPast;

  // When there is a product we show the cell with a product or nothing if this cell belongs to a group of cells with product (draggables)
  return (
    <>
      <div
        className={`${basicClasses} ${isOpen ? 'is-open' : ''}  ${
          wasYesterdayWeekend ? 'forecast__cell--weekend' : ''
        }  ${isWeekendTomorrow ? 'forecast__cell--preweekend' : ''}`}
        onClick={onClickCell}
        style={{ ...style, '--spacing': `${columnSpacing - columnWidth}px` }}
        data-test={format(cell?.date, 'dd')}
      >
        {showProductCellSelector && (
          <ForecastProductCellSelector
            cell={cell}
            hideBlockActions={true}
            onClose={onCloseProductSelector}
            onChange={onChangeProductSelectorValue}
            spacing={columnSpacing}
            scrolledFocusedDate={scrolledFocusedDate}
            columnWidth={columnWidth}
          />
        )}
        {!isPreviousTheSame && (
          <RenderBodyCellDrag
            color={color}
            rowHeight={rowHeight}
            columnWidth={columnWidth}
            addProductAfterDragCell={addProductAfterDragCell}
            cell={cell}
            customStyles={customStyles}
            productBulletStyles={productBulletStyles}
            maxNextTheSame={maxNextTheSame}
            isFinished={isFinished}
            productStatus={product.status}
            title={
              product.isSpecialEffort
                ? product.name
                : `${product.name} - ${product.projectName} - ${product.clientName}`
            }
            subtitle={product?.clientName}
            isSpecial={product.isSpecialEffort}
            isLocked={isLocked || !noBillingOrCanEditFromPast}
            forecastCommentList={commentsList}
          />
        )}
      </div>
    </>
  );
}
