import { useCallback, useEffect, useMemo, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';

import {
  Employee,
  EmployeeArea,
  EmployeeAreaColorForChart,
  HttpStatus,
  ModalConfirmation,
  PageName,
  Paginated,
  Table,
  TableAction,
  TableCellBadge,
  __OldRole,
  enumKeyFromValue,
  extractErrorsFromProblemDetailsExtension,
  getBooleanFormatedAsYesOrNo,
  getDateFormated,
  getTeamName,
  isAbortError,
  longTeamName,
  toastService,
  useChangePageTitle
} from '~/app/shared';
import Filter from '~/app/shared/filter/Filter';
import { TableCellDate } from '~/app/shared/table/TableCellDate';
import { RefreshStoreType, useRefreshStore } from '~/store';

import { employeesService } from '.';
import * as data from '../../../app/shared/table/table.helper.json';
import { EmployeesStoreType, useEmployeesStore } from '../employees.store';

const rawColumns = [
  {
    Header: 'employees.list.columns.id',
    accessor: 'id',
    hidden: true
  },
  {
    Header: 'employees.list.columns.name',
    accessor: 'name',
    width: 200,
    defaultSort: true,
    defaultSortAsc: true
  },
  {
    Header: 'employees.list.columns.email',
    accessor: 'email'
  },
  {
    Header: 'employees.list.columns.code',
    accessor: 'code',
    width: 70
  },
  {
    Header: 'employees.list.columns.hiringDate',
    accessor: 'hiringDate',
    Cell: ({ value }) => <> {<TableCellDate text={getDateFormated(value)} />}</>,
    width: 100
  },
  {
    Header: 'employees.list.columns.endDate',
    accessor: 'endDate',
    Cell: ({ value }) => <> {value ? <TableCellDate text={getDateFormated(value)} /> : ''}</>,
    width: 100
  },
  {
    Header: 'employees.list.columns.area',
    accessor: 'area',
    Cell: ({ value }) => {
      const key = enumKeyFromValue(EmployeeArea, value);
      return <TableCellBadge text={key} color={EmployeeAreaColorForChart()[key]} />;
    },
    width: 80
  },
  {
    Header: 'employees.list.columns.employeeRole',
    accessor: 'employeeRole',
    Cell: ({ value }) => <TableCellBadge text={value?.name} />,
    width: 100
  },
  {
    Header: 'employees.list.columns.oldRole',
    accessor: 'role',
    Cell: ({ value }) => <TableCellBadge text={enumKeyFromValue(__OldRole, value)} />,
    width: 90
  },
  {
    Header: 'employees.list.columns.unit',
    accessor: 'unit',
    Cell: ({ value }) => <TableCellBadge text={value ? value.name : ''} />,
    width: 120
  },
  {
    Header: 'employees.list.columns.team',
    accessor: 'team',
    Cell: ({ value }) => <TableCellBadge text={value ? getTeamName(value.number) : ''} />,
    width: 70
  },
  {
    Header: 'employees.list.columns.managementTeam',
    accessor: 'managementTeam',
    Cell: ({ value }) => <TableCellBadge text={value ? longTeamName(value) : ''} />,
    width: 70
  },
  {
    Header: 'employees.list.columns.isExternal',
    accessor: 'isExternal',
    Cell: ({ value }) => <> {<TableCellDate text={getBooleanFormatedAsYesOrNo(value)} />}</>,
    width: 40
  }
];

export const EmployeesList: React.FunctionComponent = () => {
  const [t] = useTranslation();

  const [id, setId] = useState<number | null>(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState<boolean>(false);

  const columns = useMemo(() => rawColumns.map((column) => ({ ...column, Header: t(column.Header) })), [t]);

  const [refreshState, refreshDispatch] = useRefreshStore();
  const [employeesState, employeesDispatch] = useEmployeesStore();
  const servicesManagerRole = __OldRole.ServicesManager;

  const employeeTableRowActions = useMemo(
    () => ({
      [TableAction.Edit]: (employee: Employee): void =>
        employeesDispatch({ type: EmployeesStoreType.SET_SELECTED, payload: employee.id }),
      [TableAction.Delete]: (employee: Employee): void => {
        if (employee.role.toString() !== servicesManagerRole) {
          showDeleteModal(employee.id);
        } else {
          toastService.errorMessage(t('employees.delete.cant-delete-manager'));
        }
      }
    }),
    [employeesDispatch, servicesManagerRole, t]
  );

  useEffect(
    function onUnmount() {
      return (): void => {
        refreshDispatch({ type: RefreshStoreType.SET_REFRESH });
        employeesDispatch({ type: EmployeesStoreType.SET_FILTER, payload: null });
      };
    },
    [employeesDispatch, refreshDispatch]
  );

  function showDeleteModal(id: number): void {
    setId(id);
    setDisplayConfirmationModal(true);
  }

  function hideConfirmationModal(): void {
    setDisplayConfirmationModal(false);
  }

  const deleteEmployee = useCallback(async (): Promise<void> => {
    try {
      const response = await employeesService.delete(id);

      if (response.status === HttpStatus.StatusOK) {
        toastService.success(t('employees.delete.success'));

        refreshDispatch({ type: RefreshStoreType.SET_REFRESH });
      }

      if (response.status === HttpStatus.StatusBadRequest) {
        const errors = extractErrorsFromProblemDetailsExtension(response.data, { format: 'list' });

        toastService.errorMessage(errors as string);
      }
    } catch (error) {
      if (!isAbortError(error)) {
        toastService.error(error);
      }
    } finally {
      setDisplayConfirmationModal(false);
    }
  }, [id, refreshDispatch, t]);

  const fetchData = useCallback(
    async (
      abortController,
      pageIndex,
      pageSize,
      sortId = data['employees'].defaultSortingColumn,
      isAsc = data['employees'].defaultAscOrder
    ): Promise<Paginated<Employee>> => {
      const result = await employeesService.get({
        filter: employeesState?.filter ?? '',
        paginationParameters: {
          pageNumber: pageIndex,
          pageSize: pageSize,
          propertySort: sortId,
          isAscending: isAsc
        },
        abortController
      });

      return result.data;
    },
    [employeesState?.filter]
  );

  const handleOnChangeFilterEvent = useCallback(
    (filter): void => {
      refreshDispatch({ type: RefreshStoreType.SET_REFRESH });
      employeesDispatch({ type: EmployeesStoreType.SET_FILTER, payload: filter });
    },
    [employeesDispatch, refreshDispatch]
  );
  useChangePageTitle(PageName.EMPLOYEES);

  return (
    <Card>
      <Card.Header>{t('employees.list.subtitle')}</Card.Header>

      <Card.Body>
        <Filter
          title={t('employees.filter.title')}
          defaultValue={employeesState?.filter}
          placeholder={t('common.employees-filter-placeholder')}
          onChange={handleOnChangeFilterEvent}
        />

        <Table
          columns={columns}
          fetchData={fetchData}
          pageSize={10}
          actions={employeeTableRowActions}
          refresh={refreshState.last}
        />

        <ModalConfirmation
          showModal={displayConfirmationModal}
          confirmModal={deleteEmployee}
          hideModal={hideConfirmationModal}
        />
      </Card.Body>
    </Card>
  );
};
