import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Employee,
  FormAsyncSelect,
  FormSelectOption,
  HttpStatus,
  Team,
  Unit,
  employeeManagersPromiseFilter,
  extractErrorsFromProblemDetailsExtension,
  isAbortError,
  toastService,
  unitsPromiseFilter,
  unitsService
} from '~/app/shared';
import { RefreshStoreType, useRefreshStore } from '~/store';
import { teamsService } from '.';
import { TeamsStoreType, useTeamsStore } from '../teams.store';

export function TeamsCreate({ id = null }) {
  const [t] = useTranslation();
  const abortController = useRef<AbortController>(new AbortController());
  const [isSubmitting, setIsSubmitting] = useState(!!id);
  const [units, setUnits] = useState<Unit[]>();
  const [managers, setManagers] = useState<Employee[]>();
  const [, teamsDispatch] = useTeamsStore();
  const [, refreshDispatch] = useRefreshStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm();

  useEffect(() => {
    function onLeave() {
      abortController.current.abort();
      refreshDispatch({ type: RefreshStoreType.SET_REFRESH });
      teamsDispatch({ type: TeamsStoreType.SET_SELECTED, payload: null });
      reset({});
    }

    return onLeave;
  }, [refreshDispatch, reset, teamsDispatch]);

  useEffect(() => {
    async function fetchDataIfIdExists(abortController: AbortController) {
      if (!id) {
        return;
      }

      setIsSubmitting(true);

      const { data: team } = await teamsService.getById(id, abortController);

      reset({
        ...team,
        unit: {
          value: team.unit.id,
          label: team.unit.name
        },
        manager: team.managerId ? { value: team.manager.id, label: team.manager.name } : null
      });

      setIsSubmitting(false);
    }

    const abortController = new AbortController();
    fetchDataIfIdExists(abortController).catch((error) => {
      if (!isAbortError(error)) {
        console.error(error);
      }
    });

    return () => abortController.abort();
  }, [id, reset]);

  function resetForm(force = true) {
    teamsDispatch({ type: TeamsStoreType.SET_SELECTED, payload: null });
    reset({});

    if (force) {
      refreshDispatch({ type: RefreshStoreType.SET_REFRESH });
    }
  }

  const onSubmit = async (data) => {
    if (errors?.length) return;
    setIsSubmitting(true);

    const team: Team = {
      number: data.number,
      alias: data.alias,
      isManagement: data.isManagement,
      unit: { id: data.unit?.value },
      managerId: data.manager?.value
    };

    try {
      if (data.id) {
        const response = await teamsService.update({ id: data.id, ...team });

        if (response.status === HttpStatus.StatusOK) {
          toastService.success(t('teams.create.success-update'));

          resetForm();
        } else {
          const errors = extractErrorsFromProblemDetailsExtension(response.data, { format: 'list' });

          toastService.errorMessage(errors as string);
        }
      } else {
        const response = await teamsService.create(team);

        if (response.status === HttpStatus.StatusOK) {
          toastService.success(t('teams.create.success-create'));

          resetForm();
        } else {
          const errors = extractErrorsFromProblemDetailsExtension(response.data, { format: 'list' });

          toastService.errorMessage(errors as string);
        }
      }
    } catch (error) {
      if (!isAbortError(error)) {
        toastService.error(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onClickCancel = () => {
    setIsSubmitting(true);
    resetForm(false);
    setTimeout(() => setIsSubmitting(false), 10);
  };

  function unitsToSelectPromise(inputValue: string): Promise<FormSelectOption<unknown>[]> {
    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    return unitsPromiseFilter(units, unitsService.getAll, setUnits, inputValue, true, abortController.current);
  }

  const managersToSelectPromise = async (inputValue: string) =>
    await employeeManagersPromiseFilter(managers, setManagers, inputValue);

  return (
    <Card bg="light" className="mb-3">
      <Card.Header>{t(id ? 'teams.edit.title' : 'teams.create.title', [id])}</Card.Header>
      <Card.Body>
        {isSubmitting ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="number">
              <Form.Label className="form-label-required">{t('teams.create.number')}</Form.Label>
              <Form.Control
                {...register('number', {
                  required: { value: true, message: t('form.errors.required') },
                  max: { value: 100, message: t('form.errors.valueOutOfRange', [1, 100]) },
                  min: { value: 1, message: t('form.errors.valueOutOfRange', [1, 100]) }
                })}
                isInvalid={errors.number}
                placeholder={t('teams.create.number-placeholder')}
                type="number"
              />
              <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="alias">
              <Form.Label>{t('teams.create.alias')}</Form.Label>
              <Form.Control
                {...register('alias', {
                  required: false,
                  max: { value: 50, message: t('form.errors.maxLength') }
                })}
                isInvalid={errors.alias}
                placeholder={t('teams.create.alias-placeholder')}
              />
              <Form.Control.Feedback type="invalid">{errors.alias?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="unit">
              <Form.Label className="form-label-required">{t('teams.create.unit')}</Form.Label>
              <FormAsyncSelect
                name="unit"
                placeholder={t('teams.create.unit-placeholder')}
                promiseOptions={unitsToSelectPromise}
                control={control}
                rules={{ required: { value: true, message: t('form.errors.required') } }}
              />
              <Form.Control.Feedback type="invalid">{errors.unit?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="manager">
              <Form.Label className="form-label">{t('teams.create.manager')}</Form.Label>
              <FormAsyncSelect
                name="manager"
                placeholder={t('teams.create.manager-placeholder')}
                promiseOptions={managersToSelectPromise}
                control={control}
                isClearable
              />
            </Form.Group>

            <Form.Group className="mb-3 teams-create-management" controlId="isManagement">
              <span>{t(`teams.create.delivery`)}</span>
              <Form.Switch disabled={id} {...register('isManagement')} />
              <span>{t(`teams.create.management`)}</span>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button variant="secondary" type="reset" className="mx-2" onClick={onClickCancel}>
                {t('common.cancel')}
              </Button>

              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <Spinner animation="border" size="sm" className="ml-2" /> : t('common.save')}
              </Button>
            </div>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
}
