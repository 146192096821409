import debounce from 'debounce-async';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { productsService } from '~/app/products';

import Stack from 'react-bootstrap/Stack';
import { ExternalLink } from 'tabler-icons-react';
import {
  BillableType,
  buildSpecialEffortProductsWithIcons,
  FAST_FETCH_DEBOUNCE,
  FormAsyncSelect,
  getProductBulletStyles,
  getSavedFilter,
  getSavedTeamId,
  longProductName,
  MaterialCloseIcon,
  Product,
  productsToSelectOptions,
  Show,
  SPECIAL_KEY,
  Tooltip
} from '~/app/shared';
import { ProductType } from '~/app/shared/product/product-type.enum';
import { ForecastCell } from '../forecast-cell.model';
import { GetForecastType } from '../forecast.store';
import './forecast-product-cell-selector.css';
import { ForecastSpecialEvent } from './ForecastSpecialEvent';

type ForecastProductCellSelectorProps = {
  cell: ForecastCell;
  onChange?: (value: ProductSelectorValueProps, all?: boolean) => void;
  onClose?: () => void;
  initialProducts?: Product[];
  isNew?: boolean;
  hideBlockActions?: boolean;
  spacing?: number;
  columnWidth: number;
  scrolledFocusedDate: Date;
};

const POPUP_COLUMNS_WIDTH = 6;

export const OptionBullet = (props) => {
  const { product, color, ...productBulletStyles } = getProductBulletStyles(props.data.value);

  return (
    <div className="forecast-selector__option">
      <span className="forecast-selector__bullet" style={{ ...productBulletStyles }}></span>
      <components.Option {...props} className="px-4" />
    </div>
  );
};

export const SingleValue = ({ children, ...props }: any) => {
  const { product, color, ...productBulletStyles } = getProductBulletStyles(props.data.value);

  return (
    <div className="forecast-selector__option">
      <span
        className="forecast-selector__bullet forecast-selector__bullet--value"
        style={{ ...productBulletStyles }}
      ></span>
      <components.SingleValue {...props} className="forecast-selector__option--value">
        {children}
      </components.SingleValue>
    </div>
  );
};

export const isSpecialEvent = (selectedEvent: number, watchedProduct) => {
  return (
    (selectedEvent !== null && !watchedProduct?.value?.color) ||
    watchedProduct?.value?.clientName === SPECIAL_KEY ||
    watchedProduct?.value?.type !== ProductType.Regular
  );
};

export type ProductSelectorValueProps = {
  product: Product;
  billableType: BillableType;
  specialEffort?: number;
  comment?: string;
};

export function ForecastProductCellSelector(props: ForecastProductCellSelectorProps) {
  const {
    cell,
    onChange = () => null,
    onClose = () => null,
    isNew = false,
    hideBlockActions = true,
    spacing,
    columnWidth,
    scrolledFocusedDate
  } = props;

  const [t] = useTranslation();

  const [billableType, setBillableTypeState] = useState(BillableType.Billable);
  const [initialized, setInitialized] = useState(false);
  const [defaultProducts, setDefaultProducts] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDay, setIsDay] = useState(hideBlockActions);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCommentUpdated, setIsCommentUpdated] = useState(false);
  const [selectedSpecialEvent, setSelectedSpecialEventState] = useState(cell?.specialEffort);
  const storedYear = getSavedFilter(GetForecastType.GET_YEAR);
  const storedTeam = Number(getSavedTeamId());
  const [builtSpecialCategories] = useState(buildSpecialEffortProductsWithIcons(t));

  const setBillableType = useCallback((billableType: BillableType) => {
    setBillableTypeState(billableType);
    if (billableType !== BillableType.NonBillable) {
      setSelectedSpecialEventState(null);
    }
  }, []);

  const setSelectedSpecialEvent = useCallback((specialEffort: number) => {
    setSelectedSpecialEventState(specialEffort);
    setBillableTypeState(BillableType.None);
  }, []);

  const popoverRef = useRef(null);

  const { control, reset, watch, getValues, setValue, register } = useForm();

  const isScrolledFocusedDateInLeft = useMemo(() => cell.date < scrolledFocusedDate, [cell.date, scrolledFocusedDate]);

  const translatePopupPosition = useMemo(() => {
    if (isScrolledFocusedDateInLeft) {
      return 0;
    }

    return -(columnWidth * POPUP_COLUMNS_WIDTH);
  }, [columnWidth, isScrolledFocusedDateInLeft]);

  const watchedProduct = watch('product');

  const getData = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 1 && defaultProducts) {
        return defaultProducts;
      }

      const { data: fetchedProducts } = await productsService.getForSelector({
        year: storedYear,
        month: cell.date.getMonth() + 1,
        teamId: inputValue ? undefined : storedTeam,
        search: inputValue,
        paginationParameters: {
          pageNumber: 0,
          pageSize: 10,
          propertySort: 'name',
          isAscending: true
        }
      });

      const products = productsToSelectOptions(fetchedProducts.items);

      if (inputValue.length < 1 && !defaultProducts) {
        setDefaultProducts(products);
      }

      return products;
    },
    [defaultProducts, storedTeam, storedYear, cell.date]
  );

  const productsToSelectPromise = useMemo(() => debounce(getData, FAST_FETCH_DEBOUNCE), [getData]);

  useEffect(() => {
    if (!cell) {
      return;
    }

    const resetData = () => {
      let product = null;
      let specialEvent = null;

      if (cell?.product?.id) {
        product = { value: cell.product, label: longProductName(cell.product) };
        setSelectedSpecialEventState(null);
        setBillableType(cell?.billableType);
      } else if (cell?.specialEffort) {
        const selectedSpecialEffort = builtSpecialCategories.find((x) => x.id === cell?.specialEffort);
        specialEvent = { value: selectedSpecialEffort, label: longProductName(selectedSpecialEffort) };
        setSelectedSpecialEvent(cell?.specialEffort);
      }

      if (product) {
        reset({ product });
      }
      if (specialEvent) {
        reset({ event: specialEvent });
      }

      setInitialized(true);
    };

    resetData();
  }, [builtSpecialCategories]);

  const handleProductSelect = () => {
    if (!watchedProduct) {
      return;
    }
    if (billableType === BillableType.None) {
      setBillableType(BillableType.Billable);
    }
  };

  useEffect(() => {
    handleProductSelect();
  }, [watchedProduct]);

  const isSpecialEffort = useMemo(
    () => isSpecialEvent(selectedSpecialEvent, watchedProduct),
    [selectedSpecialEvent, watchedProduct]
  );

  enum FormKeys {
    isBillable = 'billable',
    nonBillable = 'nonbillable',
    isBilledOtherTime = 'other',
    isDay = 'isDay',
    isBlock = 'isBlock',
    specialCategory = 'specialCategory',
    nonBillableBySales = 'nonBillableBySales'
  }

  const handleSpecialEvent = (id: number) => {
    setSelectedSpecialEvent(id);

    const specialEvent = builtSpecialCategories.find((x) => x.id === id);

    if (!specialEvent) {
      return;
    }

    if (specialEvent.clientName === SPECIAL_KEY) {
      reset({
        product: null,
        billableType: BillableType.None,
        specialEffort: specialEvent.id
      });
    }
  };

  const onSubmit = useCallback(
    (comment: string, isAll = false) => {
      const value: ProductSelectorValueProps = {
        product: watchedProduct?.value ?? null,
        billableType,
        specialEffort: selectedSpecialEvent || null,
        comment: comment
      };

      onChange(value, isAll);
      onClose();
      setIsSubmitting(false);
    },
    [watchedProduct?.value, billableType, selectedSpecialEvent, onChange, onClose]
  );

  const onClickDelete = useCallback(
    (isAll = false) => {
      const value: ProductSelectorValueProps = {
        product: null,
        billableType: BillableType.Billable,
        comment: null
      };

      onChange(value, isAll);
      onClose();
    },
    [onChange, onClose]
  );

  const handleDeleteDay = () => {
    if (isDeleting) {
      return;
    }
    setIsDeleting(true);
    setTimeout(() => {
      onClickDelete();
      setIsDeleting(false);
    }, 100);
  };

  const handleOnSubmit = useCallback(() => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setTimeout(() => onSubmit(getValues('comment'), !isDay), 100);
  }, [isSubmitting, onSubmit, getValues, isDay]);

  const handleDeleteBlock = useCallback(() => {
    if (isDeleting) {
      return;
    }
    setIsDeleting(true);
    setTimeout(() => {
      onClickDelete(true);
      setIsDeleting(false);
    }, 100);
  }, [isDeleting, onClickDelete]);

  const onChangeComment = useCallback(() => {
    if (isCommentUpdated) {
      return;
    }

    setIsCommentUpdated(true);
  }, [isCommentUpdated]);

  const onClearComment = () => {
    if (isCommentUpdated) {
      setIsCommentUpdated(false);
    }
    setValue('comment', null);
  };

  function getForecastSelectorClasses(isDay) {
    const selectClass = 'forecast-selector__isSelect';
    const nonSelectClass = 'forecast-selector__isNoSelect';
    return {
      forecastSelectorIsDay: isDay ? selectClass : nonSelectClass,
      forecastSelectorIsBlock: !isDay ? selectClass : nonSelectClass
    };
  }

  const isBillableTypeDisabled = () => {
    return !watchedProduct?.value || watchedProduct.value.type !== ProductType.Regular;
  };

  const { forecastSelectorIsDay, forecastSelectorIsBlock } = getForecastSelectorClasses(isDay);

  const rect = popoverRef?.current?.getBoundingClientRect();

  // TODO: Remove this, as it is creating the portal each time the component makes a re-render
  const ForecastSelectorPortal = () =>
    createPortal(
      <div className="forecast-selector" style={{ top: rect?.top, left: rect?.left }}>
        <div
          className="forecast-selector__popover"
          style={{
            marginLeft: `${spacing}px`,
            transform: `translateX(${translatePopupPosition}px)`
          }}
        >
          {initialized && (
            <Form className="forecast-selector__body">
              <div className="forecast-selector__head">
                <Show
                  when={!!cell?.product?.id || !!cell?.specialEffort}
                  fallback={
                    <span className={forecastSelectorIsDay}>{t(`forecast.${isNew ? 'create' : 'actions'}.day`)}</span>
                  }
                >
                  <span className={forecastSelectorIsDay}>{t(`forecast.${isNew ? 'create' : 'actions'}.day`)}</span>

                  <Form.Group controlId="dayToBlock" className="forecast-selector__switch">
                    <Form.Switch
                      checked={!isDay}
                      onChange={() => setIsDay(!isDay)}
                      className="forecast-selector__switch--input"
                    />
                  </Form.Group>

                  <span className={forecastSelectorIsBlock}>{t('forecast.actions.block')}</span>
                </Show>
              </div>

              <Show when={!!builtSpecialCategories}>
                <ForecastSpecialEvent
                  specialEffortProducts={builtSpecialCategories}
                  onSelectSpecialEvent={handleSpecialEvent}
                  selectedSpecialEvent={selectedSpecialEvent}
                />
              </Show>

              <div>
                <div className="forecast-selector__products">
                  <h4>{t('forecast.actions.product')}</h4>
                  <Stack direction="horizontal">
                    <FormAsyncSelect
                      small
                      name="product"
                      className="custom-form-select--default-height"
                      placeholder={t('forecast.create.product-placeholder')}
                      promiseOptions={productsToSelectPromise}
                      control={control}
                      components={{ Option: OptionBullet, SingleValue }}
                    />

                    <Button type="button" variant="link" disabled={!watchedProduct?.value?.projectId}>
                      <a
                        href={`/projects/${watchedProduct?.value?.projectId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ExternalLink />
                      </a>
                    </Button>
                  </Stack>
                </div>

                <h4>{t('forecast.actions.billable')}</h4>
                <div className="forecast-selector__radio">
                  <div className="forecast-selector__types">
                    <Form.Group controlId={FormKeys.isBillable}>
                      <Tooltip title={t('forecast.billableTypes.Yes')}>
                        <Form.Label onClick={() => setBillableType(BillableType.Billable)}>
                          <Form.Check
                            name={FormKeys.isBillable}
                            label={t('forecast.create.billable')}
                            type="radio"
                            className="forecast-selector__switch forecast-selector__switch--label"
                            defaultChecked={billableType === BillableType.Billable && !isSpecialEffort}
                            disabled={isBillableTypeDisabled()}
                          />
                        </Form.Label>
                      </Tooltip>
                    </Form.Group>
                    <Form.Group controlId={FormKeys.isBilledOtherTime}>
                      <Tooltip title={t('forecast.billableTypes.Other')}>
                        <Form.Label onClick={() => setBillableType(BillableType.BilledOtherTime)}>
                          <Form.Check
                            name={FormKeys.isBilledOtherTime}
                            label={t('forecast.create.billed-other-time')}
                            type="radio"
                            className="forecast-selector__switch forecast-selector__switch--label"
                            defaultChecked={billableType === BillableType.BilledOtherTime && !isSpecialEffort}
                            disabled={isBillableTypeDisabled()}
                          />
                        </Form.Label>
                      </Tooltip>
                    </Form.Group>
                  </div>
                  <div className="forecast-selector__types">
                    <Form.Group controlId={FormKeys.nonBillable}>
                      <Tooltip title={t('forecast.billableTypes.No')}>
                        <Form.Label onClick={() => setBillableType(BillableType.NonBillable)}>
                          <Form.Check
                            name={FormKeys.nonBillable}
                            label={t('forecast.create.non-billable')}
                            type="radio"
                            className="forecast-selector__switch forecast-selector__switch--label"
                            defaultChecked={billableType === BillableType.NonBillable && !isSpecialEffort}
                            disabled={isBillableTypeDisabled()}
                          />
                        </Form.Label>
                      </Tooltip>
                    </Form.Group>
                    <Form.Group controlId={FormKeys.nonBillableBySales}>
                      <Tooltip title={t('forecast.billableTypes.Commercial')}>
                        <Form.Label>
                          <Form.Check
                            name={FormKeys.nonBillableBySales}
                            label={t('forecast.create.non-billable-by-sales')}
                            type="radio"
                            className="forecast-selector__switch forecast-selector__switch--label"
                            defaultChecked={billableType === BillableType.NonBillableBySales && !isSpecialEffort}
                            disabled={true}
                          />
                        </Form.Label>
                      </Tooltip>
                    </Form.Group>
                  </div>
                </div>

                <h4 className="forecast-selector__comments-title">{t('forecast.actions.comment')}</h4>

                <div>
                  <Show when={isDay}>
                    <Form.Group controlId="comment">
                      <Form.Label className="forecast-selector__comments-label">
                        <Form.Control
                          {...register('comment', {
                            required: false,
                            max: { value: 250, message: t('form.errors.maxLength') }
                          })}
                          name="comment"
                          size="sm"
                          type="string"
                          as="textarea"
                          className="forecast-selector__comments"
                          rows={3}
                          defaultValue={cell?.comment}
                          disabled={!isDay}
                          placeholder={t('forecast.create.comment-placeholder')}
                          onBlur={onChangeComment}
                        />
                        <div
                          className={`forecast-selector__comments-col ${
                            isCommentUpdated || (!isCommentUpdated && isDay && cell?.comment?.length > 0)
                              ? ''
                              : 'is-hidden'
                          }`}
                        >
                          <Button
                            variant="light"
                            disabled={!isDay}
                            className="forecast-selector__close shadow-none"
                            onClick={onClearComment}
                          >
                            <MaterialCloseIcon size={16} />
                          </Button>
                        </div>
                      </Form.Label>
                    </Form.Group>
                  </Show>

                  <Show when={!isDay}>
                    <span className="forecast-selector__no-comment">{t('forecast.actions.comment-block')}</span>
                  </Show>
                </div>
              </div>

              <div className="forecast-selector__actions">
                <Show when={!isNew}>
                  <button
                    type="button"
                    onClick={isDay ? handleDeleteDay : handleDeleteBlock}
                    className="btn forecast-selector__action forecast-selector__action--reset"
                  >
                    {t('common.delete')}
                  </button>
                </Show>

                <button
                  type="submit"
                  className="btn forecast-selector__action forecast-selector__action--submit"
                  disabled={
                    (!watchedProduct?.value && selectedSpecialEvent === 0) ||
                    (billableType === BillableType.None && watchedProduct?.value)
                  }
                  onClick={handleOnSubmit}
                >
                  {t('common.save')}
                </button>
              </div>
            </Form>
          )}
        </div>
        <div className="forecast-selector__overlay" onClick={onClose}></div>
      </div>,
      document.querySelector('body')
    );

  return (
    <div className="forecast-selector-popover" ref={popoverRef}>
      <ForecastSelectorPortal />
    </div>
  );
}
