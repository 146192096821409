import { createStore, ReducerType, useStore } from 'react-hookstore';
import { GenericPayload } from '~/app/core';
import { __OldRole, EmployeeRole } from '../employee';

const name = 'ROOT/AUTH';

enum Type {
  SET_ID = 'ROOT/AUTH/SET_ID',
  SET_NAME = 'ROOT/AUTH/SET_NAME',
  SET_USER_NAME = 'ROOT/AUTH/SET_USER_NAME',
  SET_ROLES = 'ROOT/AUTH/SET_ROLES',
  SET_EMPLOYEE_ROLE = 'ROOT/AUTH/SET_EMPLOYEE_ROLE',
  SET_OLD_ROLE = 'ROOT/AUTH/SET_OLD_ROLE',
  SET_EMPLOYEE_UNITID = 'ROOT/AUTH/SET_EMPLOYEE_UNITID',
  SET_EMPLOYEE_ROLE_NAME = 'ROOT/AUTH/SET_EMPLOYEE_ROLE_NAME',
  SET_MAINTENANCE = 'ROOT/AUTH/SET_MAINTENANCE'
}

type Payload = GenericPayload<Type>;

interface State {
  id: number;
  name: string;
  username: string;
  roles: string[];
  role: EmployeeRole | null;
  // TODO: Remove oldRole
  oldRole: __OldRole;
  unitId: number;
  roleName: string;
  maintenance: boolean;
}

const state: State = {
  id: null,
  name: null,
  username: null,
  roles: [],
  role: null,
  oldRole: null,
  unitId: null,
  roleName: null,
  maintenance: false
};

const reducers: ReducerType<State, Payload> = function (state: State, { type, payload }) {
  switch (type) {
    case Type.SET_ID:
      return { ...state, id: payload };
    case Type.SET_NAME:
      return { ...state, name: payload };
    case Type.SET_USER_NAME:
      return { ...state, username: payload };
    case Type.SET_ROLES:
      return { ...state, roles: payload };
    case Type.SET_EMPLOYEE_ROLE:
      return { ...state, role: payload };
    case Type.SET_OLD_ROLE:
      return { ...state, oldRole: payload };
    case Type.SET_EMPLOYEE_ROLE_NAME:
      return { ...state, roleName: payload };
    case Type.SET_EMPLOYEE_UNITID:
      return { ...state, unitId: payload };
    case Type.SET_MAINTENANCE:
      return { ...state, maintenance: payload };
    default:
      return { ...state };
  }
};

const store = createStore<State>(name, state, reducers);

export const AuthStoreType = Type;
export type AuthStoreState = State;
export const useAuthStore = () => useStore<State>(store);
