import {
  ContentTypes,
  HttpFetchResponse,
  HttpMethod,
  Paginated,
  PaginationParameters,
  ProblemDetails,
  ProblemDetailsErrorsExtension,
  Team
} from '~/app/shared';

type TeamWithPermission = Team & {
  hasViewPermission: boolean;
  hasEditPermission: boolean;
};

export interface TeamsServiceGetOptions {
  filter: string;
  paginationParameters: PaginationParameters;
  isManagement?: boolean;
  abortController: AbortController;
}

class TeamsService {
  public getSortProperty(propertySort: string): string {
    const properties = {
      unit: 'UnitName',
      number: 'Number',
      'manager.name': 'ManagerName',
      currentEmployeesCount: 'CurrentEmployeesCount',
      isManagement: 'IsManagement'
    };

    return properties[propertySort] || properties.number;
  }

  public async create(team: Team): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/team';

    const body = {
      number: team.number,
      alias: team.alias,
      unitId: team.unit.id,
      managerId: team.managerId,
      isManagement: team.isManagement
    };

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async get(options: TeamsServiceGetOptions): Promise<HttpFetchResponse<Paginated<Team>>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/team/filtered';
    url.searchParams.set('pageNumber', `${options.paginationParameters.pageNumber}`);
    url.searchParams.set('pageSize', `${options.paginationParameters.pageSize}`);
    url.searchParams.set('propertySort', this.getSortProperty(options.paginationParameters.propertySort));
    url.searchParams.set('isAscending', `${options.paginationParameters.isAscending}`);
    url.searchParams.set('filter', options.filter);

    if (options.isManagement !== undefined) {
      url.searchParams.set('isManagement', options.isManagement.toString());
    }

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getCurrent(skip = 0, take = 100): Promise<HttpFetchResponse<Team[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/team/current/${skip}/${take}`;

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody?.items, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getByIdWithPermission(
    id: string | number,
    abortController: AbortController
  ): Promise<HttpFetchResponse<TeamWithPermission>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/team/${id}/permission`;

    const response = await fetch(url, { signal: abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getById(id: number, abortController: AbortController): Promise<HttpFetchResponse<Team>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/team/${id}`;

    const response = await fetch(url, { signal: abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getAll(skip = 0, take = 1000): Promise<HttpFetchResponse<Team[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/team/${skip}/${take}`;

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody.items, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getByUnitId(
    unitId: string | number,
    abortController: AbortController
  ): Promise<HttpFetchResponse<Team[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/team/unit/${unitId}`;

    const response = await fetch(url, { signal: abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody.items, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async delete(id: string | number): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/team/${id}`;

    const response = await fetch(url, { method: HttpMethod.DELETE });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async update(team: Team): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/team`;

    const body = {
      number: team.number,
      alias: team.alias,
      unitId: team.unit.id,
      id: team.id,
      managerId: team.managerId
    };

    const response = await fetch(url, {
      method: HttpMethod.PUT,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }
}

export const teamsService = new TeamsService();
